import { functions } from '@/config/firebase.config';

export const fetchSources = () => {
  return functions
    .httpsCallable('fetchSources')()
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};
