<template>
  <TrackDetailsProvider
    :selectedTrack="selectedTrack"
    ref="trackDetailsProvider"
  >
    <template
      #default="{
        openCountryDetails,
        searchCountries,
        highlightCountries,
        resetCountriesColour,
        closeCountryDetailsCard,
        closeCompareCard,
      }"
    >
      <div>
        <div :class="$style.logoContainer">
          <img
            :class="$style.logo"
            src="@/assets/img/logo-sm.png"
            alt="Rightsholders Logo"
          />
        </div>

        <div :class="$style.pageContent">
          <div v-if="selectedTrack" :class="$style.selectedTrackBlock">
            <button
              :class="$style.closeSelectedTrackBtn"
              @click="
                (selectedTrack = null),
                  resetCountriesColour(),
                  closeCountryDetailsCard(),
                  closeCompareCard()
              "
            >
              <BaseIcon icon="times" />
            </button>
            <div :class="$style.selectedTrack">
              <span :class="$style.selectedTrackTitle">
                {{ selectedTrack.title }}
              </span>
              <span :class="$style.selectedTrackIswc">{{
                selectedTrack.iswc
              }}</span>
            </div>
          </div>
          <input
            v-else
            type="text"
            :class="$style.searchInput"
            v-model="query"
            placeholder="Search catalog..."
          />
          <router-view
            v-if="searchCatalogTracksStatusSuccess && fetchSourcesStatusSuccess"
            :tracks="tracks"
            :distinctTracks="distinctTracks"
            :catalog-id="catalogId"
            :sources="sources"
            :topSources="topSources"
            :tracksBySource="tracksBySource"
            :selectedTrack="selectedTrack"
            @openCountryDetails="openCountryDetails"
            @trackSelected="
              onTrackSelected({
                track: $event,
                highlightCountries,
                searchCountries,
              })
            "
          />
        </div>
      </div>
    </template>
  </TrackDetailsProvider>
</template>
<script>
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import { searchCatalogTracks } from "@/api/catalogApi";
import { fetchSources } from "@/api/sourceApi";
import { withAsync } from "@/helpers";
import { debounce } from "lodash-es";
import { API_STATUS } from "@/constants/apiStatus";
import DetailsCard from "./components/DetailsCard";
import TrackDetailsProvider from "./components/TrackDetailsProvider";
import { prepTracksWithSources } from "./helpers/prepTracksWithSources";
import { TRACK_SOURCE_TYPE } from "./constants/trackSourceType";
const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;
export default {
  name: "Catalog",
  components: {
    DetailsCard,
    TrackDetailsProvider,
  },
  props: {
    catalogId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      query: "",
      tracks: [],
      distinctTracks: [],
      tracksBySource: {},
      sources: [],
      topSources: [],
      searchCatalogTracksStatus: IDLE,
      fetchSourcesStatus: IDLE,
      selectedTrack: null,
    };
  },
  computed: {
    ...apiStatusComputedFactory("searchCatalogTracksStatus"),
    ...apiStatusComputedFactory("fetchSourcesStatus"),
  },
  watch: {
    query(q) {
      this.searchCatalogTracksDebounced(q);
    },
    "$route.path"(path) {
      const viewMode = path.split("/").at(-1);
      if (viewMode !== this.currentViewMode) {
        this.onViewChanged();
      }
      this.currentViewMode = viewMode;
    },
  },
  created() {
    this.searchCatalogTracksDebounced = debounce(this.searchCatalogTracks, 500);
    this.searchCatalogTracks("");
    this.fetchSources();
  },
  methods: {
    onViewChanged() {
      const {
        resetCountriesColour,
        closeCountryDetailsCard,
        closeCompareCard,
      } = this.$refs.trackDetailsProvider;
      resetCountriesColour();
      closeCountryDetailsCard();
      closeCompareCard();
      if (this.selectedTrack) {
        this.selectedTrack = null;
      }
    },
    async onTrackSelected({ track, searchCountries, highlightCountries }) {
      const tracksWithSources = prepTracksWithSources({
        tracks: this.tracks,
        selectedSources: this.sources,
      });

      const trackSources = tracksWithSources[track.title]?.sources;

      if (!trackSources) return;

      const countries = await searchCountries(track.iswc);
      let countriesToHighlight = [];

      const COUNTRIES_COLOUR_MAP = {
        [TRACK_SOURCE_TYPE.MISSING]: "#EDEEF3",
        [TRACK_SOURCE_TYPE.FOUND]: "#B5B9CC",
        [TRACK_SOURCE_TYPE.POTENTIAL_DUPLICATE]: "#F6D2D2",
      };

      for (const country of countries) {
        const { source } = country;
        const status = trackSources[source]?.status;
        countriesToHighlight.push({
          ...country,
          countryColour: COUNTRIES_COLOUR_MAP[status],
        });
      }
      this.selectedTrack = track;
      highlightCountries(countriesToHighlight);
    },
    async fetchSources() {
      this.fetchSourcesStatus = PENDING;

      const { response: sources, error } = await withAsync(fetchSources);

      if (error) {
        this.fetchSourcesStatus = ERROR;
        return;
      }

      let allSources = [];
      let topSources = [];
      for (const { source, top_market, country_tag } of sources) {
        let sourceObj = {
          source,
          sourceShort:
            source.length > 7 ? source.slice(0, 7).padEnd(10, ".") : source,
          top_market,
          country_tag,
        };
        allSources.push(sourceObj);
        if (top_market === "Yes") {
          topSources.push(sourceObj);
        }
      }

      this.sources = allSources;
      this.topSources = topSources;
      this.fetchSourcesStatus = SUCCESS;
    },
    prepareDistinctTracks(tracks) {
      let obj = {};
      let presentIswcs = new Set();
      for (const track of tracks) {
        if (track.title in obj || presentIswcs.has(track.iswc)) {
          continue;
        }
        obj[track.title] = track;
        presentIswcs.add(track.iswc);
      }

      return Object.values(obj);
    },
    prepareTracksBySource(tracks) {
      let obj = {};
      for (const track of tracks) {
        const { source, iswc } = track;
        if (!(source in obj)) {
          obj[source] = {};
        }

        if (!(iswc in obj[source])) {
          obj[source][iswc] = track;
        }
      }

      return obj;
    },
    async searchCatalogTracks(q) {
      this.searchCatalogTracksStatus = PENDING;
      const { response, error } = await withAsync(() =>
        searchCatalogTracks(this.catalogId, q)
      );
      if (error) {
        this.searchCatalogTracksStatus = ERROR;
        return;
      }
      this.tracks = response.tracks;
      this.distinctTracks = this.prepareDistinctTracks(response.tracks);
      this.tracksBySource = this.prepareTracksBySource(response.tracks);
      this.searchCatalogTracksStatus = SUCCESS;
    },
  },
};
</script>
<style lang="scss" module>
.logoContainer {
  z-index: 10;
  position: absolute;
  top: 5rem;
  right: 5rem;
  width: 10rem;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}

.logo {
  max-width: 100%;
  display: block;
  height: auto;
}

.pageContent {
  z-index: 50;
  margin-top: 5rem;
  margin-left: 5rem;
}

.searchInput {
  font-size: 2.4rem;
  font-weight: bold;
  font-family: $primary-font;
  border: none;
  background: transparent;
}

.selectedTrackBlock {
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}

.closeSelectedTrackBtn {
  border: none;
  background: transparent;
  font-size: 2rem;
  color: $color-grey-light;
  cursor: pointer;
}

.selectedTrack {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.selectedTrackTitle {
  font-size: 1.8rem;
  font-weight: 600;
}

.selectedTrackIswc {
  display: block;
}

.selectedTrackIswc {
  color: $color-grey-light;
}
</style>