var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TrackDetailsProvider',{ref:"trackDetailsProvider",attrs:{"selectedTrack":_vm.selectedTrack},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var openCountryDetails = ref.openCountryDetails;
    var searchCountries = ref.searchCountries;
    var highlightCountries = ref.highlightCountries;
    var resetCountriesColour = ref.resetCountriesColour;
    var closeCountryDetailsCard = ref.closeCountryDetailsCard;
    var closeCompareCard = ref.closeCompareCard;
return [_c('div',[_c('div',{class:_vm.$style.logoContainer},[_c('img',{class:_vm.$style.logo,attrs:{"src":require("@/assets/img/logo-sm.png"),"alt":"Rightsholders Logo"}})]),_c('div',{class:_vm.$style.pageContent},[(_vm.selectedTrack)?_c('div',{class:_vm.$style.selectedTrackBlock},[_c('button',{class:_vm.$style.closeSelectedTrackBtn,on:{"click":function($event){(_vm.selectedTrack = null),
                resetCountriesColour(),
                closeCountryDetailsCard(),
                closeCompareCard()}}},[_c('BaseIcon',{attrs:{"icon":"times"}})],1),_c('div',{class:_vm.$style.selectedTrack},[_c('span',{class:_vm.$style.selectedTrackTitle},[_vm._v(" "+_vm._s(_vm.selectedTrack.title)+" ")]),_c('span',{class:_vm.$style.selectedTrackIswc},[_vm._v(_vm._s(_vm.selectedTrack.iswc))])])]):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],class:_vm.$style.searchInput,attrs:{"type":"text","placeholder":"Search catalog..."},domProps:{"value":(_vm.query)},on:{"input":function($event){if($event.target.composing){ return; }_vm.query=$event.target.value}}}),(_vm.searchCatalogTracksStatusSuccess && _vm.fetchSourcesStatusSuccess)?_c('router-view',{attrs:{"tracks":_vm.tracks,"distinctTracks":_vm.distinctTracks,"catalog-id":_vm.catalogId,"sources":_vm.sources,"topSources":_vm.topSources,"tracksBySource":_vm.tracksBySource,"selectedTrack":_vm.selectedTrack},on:{"openCountryDetails":openCountryDetails,"trackSelected":function($event){return _vm.onTrackSelected({
              track: $event,
              highlightCountries: highlightCountries,
              searchCountries: searchCountries,
            })}}}):_vm._e()],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }